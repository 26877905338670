<template>
  <div>
    <div class="px-4 pt-2 bg-white shadow sticky top-0 z-10">
      <sqr-page-header :title="inquiry.name" v-if="inquiry">
        <sqr-button icon="trash" color="white" @click="removeConfirm()" />
        <sqr-button icon="clone" color="white" class="ml-1" @click="clone()" />
        <sqr-button icon="edit" color="white" @click="rename()" class="ml-1" />
        <sqr-button
          icon="sync-alt"
          label="Modèle"
          color="white"
          @click="modelRefresh()"
          v-if="inquiry.status === 'draft'"
          class="ml-1"
        />
        <sqr-button
          icon="paper-plane"
          label="Envoyer les demandes"
          @click="send"
          :loading="sending"
          v-if="inquiry.status === 'draft'"
          class="ml-1"
        />
        <sqr-button
          icon="paper-plane"
          label="Fin des demandes"
          @click="close()"
          :loading="sending"
          v-if="inquiry.status === 'sent'"
          class="ml-1"
        />
      </sqr-page-header>
      <sqr-error-banner :error="loadError" />
      <sqr-progress v-show="loading" />
      <nav-tabs :tabs="tabs" />
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { fromPairs, omit } from 'ramda';
const { compile } = require('handlebars');

import broker from '../broker';

import SqrPageHeader from '@/sqrd-ui/SqrPageHeader';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';

import NavTabs from '@/components/NavTabs';

import nanoRef from '@/utils/nanoRef';
import stamp from '@/utils/stamp';

function buildData(questions, answers) {
  const pairs = questions.map(question => {
    let text = '';
    switch (question.type) {
      case 'radio':
      case 'select':
        text = answers[question.id]?.label ?? '';
        break;
      case 'checkbox':
        text = answers[question.id]?.map(e => '– ' + e.label).join('\n') ?? '';
        break;
      case 'file':
      case 'grid-radio':
      case 'grid-checkbox':
        text = '(unsupported)';
        break;
      // text, textarea, scale, date, time
      default:
        text = answers[question.id] ?? '';
    }
    return [question.id, text];
  });

  const transformed = fromPairs(pairs);

  return {
    ...answers,
    ...transformed
  };
}

export default {
  name: 'Inquiry',
  mixins: [broker],
  components: { SqrPageHeader, SqrButton, SqrErrorBanner, NavTabs },
  props: {
    iid: String
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('inquiry', { inquiry: 'doc' }),
    ...mapState('inquiry', ['loading', 'loadError', 'path']),
    tabs() {
      const { bid, iid } = this.$route.params;
      const fill = {
        id: 'fill',
        label: 'Informations',
        to: { name: 'inquiry-fill', params: { bid, iid } }
      };
      const close = {
        id: 'close',
        label: 'Suivi',
        to: { name: 'inquiry-close', params: { bid, iid } }
      };
      switch (this.inquiry.status) {
        case 'draft':
          return [fill];
        case 'sent':
        case 'closed':
          return [fill, close];
        default:
          return [];
      }
    }
  },
  data() {
    return {
      sending: false,
      sendError: null
    };
  },
  mounted() {
    const bid = this.bid;
    const iid = this.iid;
    this.sub({
      path: `brokers/${bid}/inquiries/${iid}`
    });
  },
  methods: {
    ...mapActions('inquiry', ['sub', 'unsub']),
    ...mapActions('updater', ['update']),
    ...mapActions('remover', ['remove']),
    async modelRefresh() {
      const path = this.inquiry?.model?.path;
      if (path) {
        const snap = await this.$db()
          .doc(path)
          .get();
        const model = { ...snap.data(), path, id: snap.id };
        this.fieldSet('model', model);
      }
    },
    fieldSet(name, value) {
      this.update({ path: this.path, doc: { [name]: value } });
    },
    async clone() {
      const name = prompt('Nom de la nouvelle demande ?');
      if (!name) return;
      const created = stamp();
      try {
        this.cloning = true;
        const bid = this.bid;
        const inquiry = {
          ...omit(['id', 'path', 'name'], this.inquiry),
          name,
          status: 'draft',
          created,
          updated: created
        };
        const res = await this.$db()
          .collection(`brokers/${bid}/inquiries`)
          .add(inquiry);
        this.$router.push({
          name: 'inquiry',
          params: { bid, iid: res.id }
        });
      } catch (error) {
        alert('erreur lors de la copie');
        console.log(error);
        return Promise.reject(error);
      } finally {
        this.cloning = false;
      }
    },
    async removeConfirm() {
      if (confirm('Supprimer la demande ?')) {
        await this.remove({ path: this.path });
        this.$router.back();
      }
    },
    async send() {
      try {
        this.sendError = null;
        this.sending = true;
        const bid = this.bid;
        const iid = this.iid;

        const dataQ = buildData(
          this.inquiry?.model?.questions ?? [],
          this.inquiry.answers ?? {}
        );
        const reference = this.inquiry?.reference;
        const data = { ...dataQ, reference };

        const recipients = this.inquiry.recipientIds
          .map(id => {
            return this.inquiry?.model?.recipients?.find(r => r.id === id);
          })
          .filter(v => Boolean(v));

        const subjectT = compile(this.inquiry?.model?.subject ?? '');
        const textT = compile(this.inquiry?.model?.text ?? '');

        const batch = this.$db().batch();
        const col = this.$db().collection(`brokers/${bid}/emails`);
        recipients.forEach(recipient => {
          batch.set(col.doc(), {
            bid,
            iid,
            to: `${recipient.name} <${recipient.email}>`,
            replyTo: this.user?.email ?? '',
            message: {
              subject: subjectT(data),
              text: textT(data)
            }
          });
        });

        const name = subjectT(data);
        batch.update(this.$db().doc(this.path), {
          name,
          status: 'sent',
          updated: stamp()
        });

        await batch.commit();
      } catch (error) {
        this.sendError = error;
        return Promise.reject(error);
      } finally {
        this.sending = false;
      }
    },
    async close() {
      try {
        this.sendError = null;
        this.sending = true;
        const bid = this.bid;
        const iid = this.iid;
        const data1 = buildData(
          this.inquiry?.model?.questions ?? [],
          this.inquiry.answers ?? {}
        );
        const reference = this.inquiry?.reference;
        const data = { ...data, reference };
        const recipients = this.inquiry.recipientCloseIds
          .map(id => {
            return this.inquiry?.model?.recipients?.find(r => r.id === id);
          })
          .filter(v => Boolean(v));

        const subjectT = compile(this.inquiry?.model?.closeSubject ?? '');
        const textT = compile(this.inquiry?.model?.closeText ?? '');

        const batch = this.$db().batch();
        const col = this.$db().collection(`brokers/${bid}/emails`);
        recipients.forEach(recipient => {
          batch.set(col.doc(), {
            bid,
            iid,
            to: `${recipient.name} <${recipient.email}>`,
            replyTo: this.user?.email ?? '',
            message: {
              subject: subjectT(data),
              text: textT(data)
            }
          });
        });

        const name = subjectT(data);
        batch.update(this.$db().doc(this.path), {
          name,
          status: 'closed',
          updated: stamp()
        });

        await batch.commit();
      } catch (error) {
        this.sendError = error;
        return Promise.reject(error);
      } finally {
        this.sending = false;
      }
    }
  }
};
</script>
